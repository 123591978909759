import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Warning } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import Alert from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';
import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import { GoToPaymentPageButton } from 'components/ResolveOrderPayment/GoToPaymentPageButton';
import { FailedPaymentResolutionType } from 'constants/FailedPaymentResolutionType';
import orderStatus from 'constants/orderStatus';
import { viewOrderURL } from 'constants/urls';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';
import { confirmCancelAmendOrder } from 'redux/modules/amend-order/actions/confirm-cancel-amend-order';
import { getFailedPaymentResolutionTypeForId } from 'redux/modules/checkout/selectors/get-failed-order-payment';
import {
  getDeliveryRunningLateByOrderId,
  getDeliveryStatusByOrderId,
} from 'redux/modules/delivery-tracking/selectors';
import {
  getAmendOrderCutOff,
  getSlotStartTime,
  getStatus,
  isAmendable,
} from 'redux/modules/orders/selectors/get-order';
import { orderIsAmending } from 'redux/modules/orders/selectors/get-order-amend-status';
import { getOrderTypeById } from 'redux/modules/orders/selectors/get-order-type-by-id';
import { isSeasonalSlotDate, seasonalCutOffDateTime } from 'utils/checkout/is-seasonal-slot-date';
import { matchOrderType } from 'utils/checkout/order-type';
import { dayjs, isAfter, isSameDay } from 'utils/date';
import { formatAmendCutOffDateTime } from 'utils/format-amend-cutoff-date';
import deliveryStatus from 'constants/deliveryStatus';
import styles from './OrderCardFooter.scss';

export interface OrderCardFooterProps {
  orderId: string;
}

function orderStatusText(
  orderStatusInfo: string | number | React.JSX.Element,
  customClassName?: string,
): React.ReactNode {
  return (
    <Typography
      element="p"
      styleAs="paragraphSmallLight"
      noMargins
      className={classnames([styles.orderStatusInfo, customClassName])}
      data-testid="order-status-information"
    >
      {orderStatusInfo}
    </Typography>
  );
}

const getViewButtonTheme = (amending: boolean, orderCanBeAmended: boolean) => {
  if (amending) {
    return {
      label: 'View order',
      theme: 'secondaryLight',
    };
  }
  if (orderCanBeAmended) {
    return {
      label: 'View or amend order',
      theme: 'primary',
    };
  }
  return {
    label: 'View order',
    theme: 'secondary',
  };
};

const OrderCardFooter = ({ orderId }: OrderCardFooterProps) => {
  const dispatch = useWtrDispatch();
  const amendable = useWtrSelector(state => isAmendable(state, orderId));
  const amending = useWtrSelector(state => orderIsAmending(state, orderId));
  const amendOrderCutOff = useWtrSelector(state => getAmendOrderCutOff(state, orderId));
  const orderType = useWtrSelector(state => getOrderTypeById(state, orderId));
  const slotStartTime = useWtrSelector(state => getSlotStartTime(state, orderId));
  const isSeasonalSlot = isSeasonalSlotDate(slotStartTime);
  const status = useWtrSelector(state => getStatus(state, orderId));
  const deliveryTrackingStatus = useWtrSelector(state =>
    getDeliveryStatusByOrderId(state, orderId),
  );
  const failedPaymentResolutionType = useWtrSelector(state =>
    getFailedPaymentResolutionTypeForId(state, orderId),
  );
  const isPaymentFailed = status === orderStatus.PAYMENT_FAILED;
  const isMissedDelivery = deliveryTrackingStatus === deliveryStatus.MISSED;
  const canPayNow =
    isPaymentFailed && failedPaymentResolutionType === FailedPaymentResolutionType.selfServe;
  const orderCanBeAmended = amendable && !isPaymentFailed;
  const isOrderBeingProcessed =
    status === orderStatus.PICKED ||
    status === orderStatus.PAYMENT_FAILED ||
    status === orderStatus.PAID;
  const runningLate =
    useWtrSelector(state => getDeliveryRunningLateByOrderId(state, orderId)) &&
    (status === orderStatus.PICKED || status === orderStatus.PAID);

  const highlightCutOffApproaching =
    isSameDay(dayjs(amendOrderCutOff).tz(), dayjs().tz()) &&
    isAfter(dayjs(amendOrderCutOff).tz(), dayjs().tz()) &&
    amendable;

  const onCancelChanges = () => {
    dispatch(confirmCancelAmendOrder());
  };

  const getOrderStatusInfo = () => {
    if (isPaymentFailed && failedPaymentResolutionType !== null) {
      const content =
        failedPaymentResolutionType === FailedPaymentResolutionType.selfServe ? (
          <span>
            <b>Payment failed</b>, please pay to receive this order
          </span>
        ) : (
          <span>
            <b>Payment failed</b>. To pay now, call us on{' '}
            <a className={styles.phoneNumber} href="tel:02039324128">
              0203 932 4128
            </a>
          </span>
        );

      return orderStatusText(
        <>
          <Warning className={styles.errorIcon} aria-hidden />
          <>{content}</>
        </>,
        styles.paymentFailedOrderStatusText,
      );
    }

    // only after the cut off, and before it's picked
    if (!amendable && !amending && !isOrderBeingProcessed) {
      return orderStatusText('Unable to amend – order in progress');
    }

    if (isMissedDelivery) {
      return orderStatusText(
        <>
          We missed you! Please call customer care on{' '}
          <a className={styles.phoneNumber} href="tel:0800188884">
            0800 188 884
          </a>
        </>,
      );
    }

    if (isOrderBeingProcessed) {
      return null;
    }

    if (isSeasonalSlot) {
      return orderStatusText(
        <>
          Amend cut-off for Christmas Entertaining items is{' '}
          <strong>{seasonalCutOffDateTime}</strong>
        </>,
      );
    }

    if (amending) {
      return orderStatusText(
        <>
          Checkout before <strong>{formatAmendCutOffDateTime(amendOrderCutOff)}</strong>
        </>,
      );
    }

    return matchOrderType<ReactNode>(
      orderType,
      {
        onGroceries: () =>
          orderStatusText(
            <>
              You have until <strong>{formatAmendCutOffDateTime(amendOrderCutOff)}</strong> to amend
              this order
            </>,
          ),
        onEntertaining: () =>
          orderStatusText(
            'Please check individual notice times before amending Entertaining orders',
          ),
        onGroceriesEntertaining: () => orderStatusText('Notice times vary for Entertaining items'),
      },
      null,
    );
  };

  const getButtons = () => {
    const url = `${viewOrderURL(Number(orderId))}`;
    const actionButtons: ReactNode[] = [];

    if (isMissedDelivery) {
      // for cases where no buttons should be rendered
      return null;
    }

    if (amending) {
      actionButtons.push([
        <TextLink
          underline="always"
          component="button"
          data-testid={`discard-amends-button-${orderId}`}
          onClick={() => onCancelChanges()}
          light
        >
          Discard amends
        </TextLink>,
      ]);
    }

    if (canPayNow) {
      actionButtons.push([
        <GoToPaymentPageButton customerOrderId={orderId} className={styles.payNowButton} />,
        <TextLink
          underline="always"
          component={Link}
          data-testid={`view-order-payment-failed-${orderId}`}
          to={viewOrderURL(Number(orderId))}
        >
          View order
        </TextLink>,
      ]);
    } else {
      const viewOrderButtonTheme = getViewButtonTheme(amending, orderCanBeAmended);
      actionButtons.push(
        <LinkAsButton
          component={Link}
          data-testid={`view-order-button-${orderId}`}
          to={url}
          label={viewOrderButtonTheme.label}
          theme={viewOrderButtonTheme.theme as never}
        />,
      );
    }

    return actionButtons.length === 0 ? null : (
      <div
        data-testid="order-card-buttons"
        className={classnames([
          styles.actionButtons,
          {
            [styles.actionButtonsPaymentFailed]: isPaymentFailed,
          },
        ])}
      >
        {actionButtons.map(button => button)}
      </div>
    );
  };

  const orderStatusInfo = getOrderStatusInfo();

  return (
    <>
      <div
        className={classnames([
          styles.orderCardFooter,
          {
            [styles.closeToCutOff]: highlightCutOffApproaching,
            [styles.orderCardFooterDark]: amending,
            [styles.orderCardFooterRed]: isPaymentFailed,
          },
        ])}
        data-testid="order-status-info-wrapper"
      >
        <span className={styles.statusInfoText}>{orderStatusInfo}</span>

        <>{getButtons()}</>
      </div>
      {runningLate ? (
        <div className={styles.alertContainer}>
          <Alert
            role="alert"
            aria-live="polite"
            type="warning"
            title="Sorry, we’re running late"
            message="Your delivery time has been updated"
          />
        </div>
      ) : null}
    </>
  );
};

export default OrderCardFooter;
