import React from 'react';
import { Card } from '@johnlewispartnership/wtr-ingredients/ingredients/Card';
import { useWtrSelector } from 'redux/hooks';
import { getStatus } from 'redux/modules/orders/selectors/get-order';
import orderStatus from 'constants/orderStatus';
import styles from './OrderCard.scss';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderCardFooter from './OrderCardFooter/OrderCardFooter';
import OrderStatus from './OrderStatus/OrderStatus';

export interface OrderCardProps {
  orderId: string;
}

const OrderCard = ({ orderId }: OrderCardProps) => {
  const status = useWtrSelector(state => getStatus(state, orderId));
  const isPaymentFailed = status === orderStatus.PAYMENT_FAILED;

  if (!orderId) {
    return null;
  }

  return (
    <Card
      data-testid={`pending-order-summary-id-${orderId}`}
      className={isPaymentFailed ? styles.paymentFailedCard : undefined}
      footer={<OrderCardFooter orderId={orderId} />}
    >
      <div className={styles.orderCardContent}>
        <OrderStatus orderId={orderId} />
        <OrderDetails orderId={orderId} />
      </div>
    </Card>
  );
};

export default OrderCard;
