import React from 'react';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import orderStatus from 'constants/orderStatus';
import {
  Clock,
  Pen,
  Van,
  Success,
  WarningTriangle,
  Warning,
  RemoveOutline,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { getStatus } from 'redux/modules/orders/selectors/get-order';
import {
  getDeliveryRunningLateByOrderId,
  getDeliveryStatusByOrderId,
} from 'redux/modules/delivery-tracking/selectors';
import deliveryStatus from 'constants/deliveryStatus';
import { useWtrSelector } from 'redux/hooks';
import type { OrderStatus as OrderStatusType } from 'constants/orderStatus';
import styles from './OrderStatus.scss';

export interface OrderStatusProps {
  orderId: string;
}

const previousOrderStates: OrderStatusType[] = [
  orderStatus.REFUND_PENDING,
  orderStatus.COMPLETED,
  orderStatus.CANCELLED,
];

const OrderStatus = ({ orderId }: OrderStatusProps) => {
  const status = useWtrSelector(state => getStatus(state, orderId));
  const orderDeliveryStatus = useWtrSelector(state => getDeliveryStatusByOrderId(state, orderId));
  const runningLate = useWtrSelector(state => getDeliveryRunningLateByOrderId(state, orderId));
  const isPreviousOrder = previousOrderStates.includes(status);

  if (!status || isPreviousOrder) {
    return null;
  }

  const getDisplayStatusAndIcon = () => {
    const orderIsPicked = status === orderStatus.PICKED || status === orderStatus.PAID;

    if (status === orderStatus.PAYMENT_FAILED) {
      return {
        status: 'PAYMENT FAILED',
        icon: (
          <Warning
            className={styles.errorIcon}
            data-testid="order-status-error-icon"
            size="medium"
          />
        ),
      };
    }

    if (orderDeliveryStatus === deliveryStatus.DELIVERED) {
      return {
        status: 'DELIVERED',
        icon: (
          <Success
            className={styles.completedIcon}
            data-testid="order-status-success-icon"
            size="medium"
          />
        ),
      };
    }

    if (status === orderStatus.AMENDING) {
      return {
        status: 'AMENDING',
        icon: (
          <Pen className={styles.amendingIcon} data-testid="order-status-pen-icon" size="medium" />
        ),
      };
    }

    if (orderIsPicked && orderDeliveryStatus === deliveryStatus.PICKED) {
      return {
        status: 'PREPARING',
        icon: <Van className={styles.vanIcon} data-testid="order-status-van-icon" size="medium" />,
      };
    }

    if (orderIsPicked && orderDeliveryStatus === deliveryStatus.OUT_FOR_DELIVERY) {
      return {
        status: 'OUT FOR DELIVERY',
        icon: runningLate ? (
          <WarningTriangle
            className={styles.warningIcon}
            data-testid="order-status-warning-icon"
            size="medium"
          />
        ) : (
          <Van className={styles.vanIcon} data-testid="order-status-van-icon" size="medium" />
        ),
      };
    }

    if (orderIsPicked && orderDeliveryStatus === deliveryStatus.NEXT) {
      return {
        status: "YOU'RE NEXT!",
        icon: <Van className={styles.vanIcon} data-testid="order-status-van-icon" size="medium" />,
      };
    }

    if (orderDeliveryStatus === deliveryStatus.MISSED) {
      return {
        status: 'WE MISSED YOU',
        icon: (
          <RemoveOutline
            className={styles.vanIcon}
            data-testid="order-status-missed-icon"
            size="medium"
          />
        ),
      };
    }

    return {
      status: 'PENDING',
      icon: (
        <Clock className={styles.pendingIcon} data-testid="order-status-clock-icon" size="medium" />
      ),
    };
  };

  const displayItems = getDisplayStatusAndIcon();

  return (
    <div className={styles.orderStatus} data-testid="pending-order-status">
      {displayItems.icon}
      <Typography styleAs="paragraphExtraSmall" noMargins className={styles.orderStatusLabel}>
        {displayItems.status}
      </Typography>
    </div>
  );
};

export default OrderStatus;
